import React from 'react'
import { Container, Flex, Box } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'

const AboutBlock01 = ({
  content: { text, images },
  reverse
}) => (
  <Box sx={{mb: [null, null, null, 200]}}>
    <Container sx={{ position: `relative` }}>
      <Flex sx={{ justifyContent: 'center', float: ['none', 'none', 'none', 'left'], mr: [null, null, null, 5], mb: 5 }}>
        <ContentImages content={{ images }} reverse={reverse} />
      </Flex>
      <Box
        sx={{
          textAlign: ['center', 'center', 'center', 'left'],
        }}
      >
        <ContentText content={text} />
      </Box>
    </Container>
  </Box>
)

export default AboutBlock01
