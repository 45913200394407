import React from 'react'
import { graphql } from 'gatsby'
import { Container, Flex } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block02'
import About from '@solid-ui-blocks/About/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import ContentButtons from '@solid-ui-components/ContentButtons'
import { normalizeBlockContentNodes } from '@blocks-helpers'

const styles = {
  heroContainer: {
    bg: `#F6F7FA`,
    pt: '100px',
  },
}
const AboutPage = props => {
  const { allBlockContent, heroImage } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo title='About' />
      {/* Modals */}
      <ModalWithTabs content={content['contact']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero heroImage={heroImage} content={content['hero']} />
      </Container>
      <About content={content['natasha-bio']} />
      <Divider space='6' />
      <About content={content['brandi-bio']} />
      <Divider space='4' />
      <Container>
        <Flex sx={{ justifyContent: 'center' }}>
          <ContentButtons content={content['call-to-action']['buttons']} />
        </Flex>
      </Container>
      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query aboutPageBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/about", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
    heroImage: file(relativePath: { eq: "about-hero.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default AboutPage
